<template>
  <div class="review-item-wrapper w-full flex flex-col sm:flex-row mb-10">
    <div
      v-if="!!image"
      class="image relative h-full mr-6"
      :style="{
        backgroundImage: image ? `url(${image})` : '',
      }"
    />

    <div class="flex flex-col h-full flex-1">
      <div class="flex flex-row items-center justify-between mb-4">
        <div class="flex flex-col sm:flex-row items-center">
          <div class="review-item-place mr-10" v-if="type === 'place'">
            <h3>{{ place.title }}</h3>
            <h5 class="date">{{ date }}</h5>
          </div>

          <div class="review-item-place mr-10" v-if="type !== 'place'">
            <h3>{{ address }}</h3>
            <h5 class="date">{{ date }}</h5>
          </div>

          <StarRating
            :read-only="true"
            :show-rating="false"
            :star-size="20"
            :active-color="'#293bf0'"
            :rating="data.rating"
          />
        </div>

        <button
          @click="onDeleteReview"
          class="flex justify-center items-center delete"
        >
          삭제
        </button>
      </div>

      <div class="review-contents flex-1">
        {{ data.contents }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import StarRating from "vue-star-rating";
import utils from "@/utils";
import services from "@/services";

export default {
  name: "ReviewItem",
  components: {
    StarRating,
  },
  props: {
    data: Object,
    onRemove: Function,
  },
  methods: {
    async onDeleteReview() {
      const success = await services.reviewRemove(this.data.id);
      if (success) {
        this.$toast.open("삭제완료");
        this.onRemove();
      } else {
        this.$toast.open({ message: "삭제실패", type: "error" });
      }
    },
  },
  computed: {
    date() {
      return dayjs(this.data.createdAt).format("YYYY-MM-DD");
    },
    type() {
      const { hasPlaceJoinOffer } = this.data;
      if (hasPlaceJoinOffer) {
        return "place";
      }
      return "interior";
    },
    place() {
      const { hasPlaceJoinOffer } = this.data;
      if (hasPlaceJoinOffer) {
        const { hasPlace } = hasPlaceJoinOffer;
        return hasPlace;
      }

      return null;
    },
    address() {
      const { hasInteriorJoinOffer } = this.data;
      if (hasInteriorJoinOffer) {
        const { hasInteriorOffer } = hasInteriorJoinOffer;
        return `${hasInteriorOffer.address} ${hasInteriorOffer.addressDetail}`;
      }

      return "";
    },
    image() {
      const { hasTradeReviewFiles } = this.data;
      if (hasTradeReviewFiles.length > 0) {
        return hasTradeReviewFiles.map((file) => utils.getImageUrl(file))[0];
      }

      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.review-item-wrapper {
  height: 140px;

  .image {
    width: 180px;
    background-color: #ddd;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .review-contents {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #737373;

    padding: 15px 30px;
    background-color: #f5f5f5;
  }

  .review-item-place {
    h3 {
      font-size: 20px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.5px;
      text-align: left;
      color: #1a1a1a;
    }
  }

  .date {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: left;
    color: #808080;
  }

  .delete {
    width: 120px;
    height: 40px;
    background-color: #bfbfbf;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: left;
    color: #fff;
    background-color: #e05050;
  }
}

@media (max-width: 639px) {
  .review-item-wrapper {
    height: auto;
    .review-item-place {
      h3 {
        font-size: 14px;
      }
      .date {
        font-size: 12px;
      }
    }
    .delete {
      font-size: 12px;
      width: 70px;
      height: 30px;
    }
    .image {
      width: 150px;
      height: 100px;
      margin: 0 auto 15px;
    }
    .review-contents {
      padding: 10px 15px;
      font-size: 12px;
    }
  }
  .vue-star-rating {
    margin-right: auto;
  }
}
</style>
