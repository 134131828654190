<template>
  <JumbotronLayout>
    <PageHeaderLayout :title="'후기내역'">
      <div
        @click="() => onChangeType('my')"
        :class="['header-item', { active: type === 'my' }]"
      >
        작성후기
      </div>
      <span class="header-space">|</span>
      <div
        @click="() => onChangeType('receive')"
        :class="['header-item', { active: type === 'receive' }]"
      >
        받은후기
      </div>
    </PageHeaderLayout>

    <div class="w-full flex justify-center items-center">
      <div class="container pt-10">
        <PaginationLayout
          :hasData="data.length > 0"
          :lastPage="lastPage"
          :onPage="onPage"
        >
          <ReviewItem
            v-for="item in data"
            :onRemove="onRemove"
            :key="item.id"
            :data="item"
          />
        </PaginationLayout>
      </div>
    </div>
  </JumbotronLayout>
</template>

<script>
import JumbotronLayout from "@/components/JumbotronLayout.vue";
import PageHeaderLayout from "@/components/PageHeaderLayout.vue";
import ReviewItem from "@/components/ReviewItem.vue";
import PaginationLayout from "@/components/PaginationLayout.vue";
import services from "@/services";
import { mapState } from "vuex";

export default {
  name: "MyReviewList",
  components: {
    JumbotronLayout,
    PageHeaderLayout,
    ReviewItem,
    PaginationLayout,
  },
  data() {
    return {
      type: "my",
      data: [],
      loading: false,
      currentPage: 1,
      lastPage: 1,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onChangeType(value) {
      this.type = value;
    },
    onPage: function(value) {
      this.currentPage = value;
      this.getData();
    },
    async getData() {
      const params = {
        paginate: 5,
        page: this.currentPage,
      };

      if (this.type === "my") {
        params.userId = this.userId;
      } else {
        params.receiveUserId = this.userId;
      }

      const { data, lastPage } = await services.reviewList(params);
      this.lastPage = lastPage;
      this.data = data;
    },
    onRemove() {
      this.getData();
    },
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
  watch: {
    type() {
      this.currentPage = 1;
      this.lastPage = 1;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.header-item {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #acb0bb;
  cursor: pointer;

  &.active {
    color: #293bf0;
  }
}

.header-space {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #acb0bb;
  opacity: 0.6;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination-wrapper {
  margin-top: 50px;
  margin-bottom: 80px;
}
</style>
